/** @format */

.frame-asset-popup {
	height: 60%;
	top: 40%;
}
.center {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80vh;
}
